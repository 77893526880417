import { FormGroupInput, Card, DropDown, Button } from "../components/index";
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

import * as VueGoogleMaps from 'vue2-google-maps';

const GlobalComponents = {
  install(Vue) {
    Vue.component("fg-input", FormGroupInput);
    Vue.component("drop-down", DropDown);
    Vue.component("card", Card);
    Vue.component("p-button", Button);

    //
    Vue.use(VueGoogleMaps, {
      load: {
        //key: 'YOUR_API_TOKEN',
        //libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
      },

      //// If you intend to programmatically custom event listener code
      //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
      //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
      //// you might need to turn this on.
      // autobindAllEvents: false,

      //// If you want to manually install components, e.g.
      //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
      //// Vue.component('GmapMarker', GmapMarker)
      //// then disable the following:
      // installComponents: true,
    });
  }
};

export default GlobalComponents;
