import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes,
  mode: process.env.IS_PROD ? 'history' : 'hash',
  linkActiveClass: "active"
});

export default router;
