import getBrowserLocale from "../util/i18n/get-browser-locale";
import {supportedLocalesInclude} from "../util/i18n/supported-locales";
import EventBus from "../EventBus";

export default {
  loadLocaleMessages,
  getStartingLocale,
  storeCurrentLocale
};

const localStorageKey = 'vt-admin-locale';

function loadLocaleMessages() {
  const locales = require.context(
    "@/core/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return messages;
}

function getStartingLocale() {
  let localeName = getBrowserLocale({countryCodeOnly: true});

  if (window.localStorage.getItem(localStorageKey) !== null) {
    localeName = window.localStorage.getItem(localStorageKey);
  }

  if (!supportedLocalesInclude(localeName)) {
    localeName = Config.LocaleDefault();
  }

  EventBus.$emit("i18n-starting-locale");

  return localeName;
}
function storeCurrentLocale(localeName) {
  // Necessary for testing purpose
  if (typeof document === 'object') {
    const shortLocaleName = localeName.split('-')[0];
    document.querySelector('html').setAttribute('lang', shortLocaleName);
  }

  window.localStorage.setItem(localStorageKey, localeName);

  return localeName;
}
