import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
import Dashboard from "@/pages/Dashboard/";
import UserProfile from "@/pages/UserProfile.vue";
import Notifications from "@/pages/Notifications.vue";
import Icons from "@/pages/Icons.vue";
import Maps from "../pages/Maps/";
import Typography from "@/pages/Typography.vue";
import TableList from "@/pages/TableList.vue";
import MdbVue from "@/pages/MdbVue/index.vue";

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: 'navigation.dashboard',
        icon: 'ti-panel',
        component: Dashboard
      },
      {
        path: "user",
        name: 'navigation.user',
        icon: 'ti-user',
        component: UserProfile
      },
      {
        path: "notifications",
        name: 'navigation.notifications',
        icon: 'ti-bell',
        component: Notifications
      },
      {
        path: "icons",
        name: 'navigation.icons',
        icon: 'ti-pencil-alt2',
        component: Icons
      },
      {
        path: "maps",
        name: 'navigation.maps',
        icon: 'ti-map',
        component: Maps
      },
      {
        path: "typography",
        name: 'navigation.typography',
        icon: 'ti-text',
        component: Typography
      },
      {
        path: "table-list",
        name: 'navigation.table',
        icon: 'ti-view-list-alt',
        component: TableList
      },
      {
        path: "mdb-vue",
        name: 'navigation.mdb',
        icon: 'ti-help',
        component: MdbVue
      }
    ]
  },
  {path: "*", component: NotFound}
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
 function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
