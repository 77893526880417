<template src="./index.html"></template>

<script>
export default {
  data() {
    return {
      config: {
        zoom: 13,
        center: {
          lat: 50.952769,
          lng: 7.129687,
        },
        mapType: 'roadmap'
      },
      center: {
        lat: 50.952769,
        lng: 7.129687,
      },
      markers: [
        {
          title: 'xxx',
          label: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: '10px',
            text: 'arena'
          },
          info: {
            title: 'werwerw',
            text: 'wwerwer wer wer wer wer wer'
          },
          position: {lat: 50.952769, lng: 7.129687},
          clickable: true,
          draggable: false
        }
      ],
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -43
        }
      },
      infoWindowPos: null,
      infoWinOpen: null,
      infoContent: '',
      currentMidx: null,
    }
  },
  computed: {},
  methods: {
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoContent = this.getInfoWindowContent(marker);

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    getInfoWindowContent: function (marker) {
      return (
        `<div class="">
          <div>
               <h4>${marker.info.title}</h4>
               ${marker.info.text}
          </div>
        </div>`);
    },
  }
};
</script>
<style>
.gm-style-iw-d h4{
  margin-top: 0;
}
</style>
