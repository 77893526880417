import Config from "@/core/Config";
export function getSupportedLocales() {
  let annotatedLocales = []

  for (const code of Object.keys(Config.Locales())) {
    annotatedLocales.push({
      code,
      name: supportedLocales[code]
    })
  }

  return annotatedLocales
}

export function supportedLocalesInclude(locale) {
  return Object.keys(Config.Locales()).includes(locale);
}
