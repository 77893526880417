import Vue from "vue";
import VueI18n from "vue-i18n";
import Config from "@/core/Config";
import Locale from "./factory/locale.factory";

Vue.use(VueI18n);

export default new VueI18n({
  locale: Locale.getStartingLocale(),
  fallbackLocale: Config.LocaleDefault(),
  messages: Locale.loadLocaleMessages(),
  dateTimeFormats: Config.DateTimeFormat(),
  numberFormats: Config.NumberFormats(),
  silentFallbackWarn: true,
  sync: true
});
