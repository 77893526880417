import Vue from "vue";
import BootstrapVue from 'bootstrap-vue';
import App from "./App";
import i18n from "./core/i18n";
import store from "./store";
import router from "./router/index";
import PaperDashboard from "./plugins/paperDashboard";
import "vue-notifyjs/themes/default.css";

Vue.use(BootstrapVue);
Vue.use(PaperDashboard);

/* eslint-disable no-new */
new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount("#app");
