import config from "@/config/config.json";

export default {
  /**
   * get all locales data
   *
   * @return Object
   * @constructor
   */
  Locales() {
    return config.locale.locales;
  },

  /**
   * get default locale
   *
   * @return string
   * @constructor
   */
  LocaleDefault() {
    return config.locale.default;
  },

  /**
   * get locale dateTimeFormat
   *
   * @return Object
   */
  DateTimeFormat() {
    return this._getLocaleData('dateTimeFormat');
  },

  /**
   * get locale numberFormats
   *
   * @return Object
   */
  NumberFormats() {
    return this._getLocaleData('numberFormats');
  },

  /**
   * helper to get config value
   * @param $value
   * @param $default
   * @return {*|null}
   */
  _get($value, $default = null) {
    return config[$value] !== undefined ? config[$value] : $default;
  },

  /**
   * helper to prepare locale data
   *
   * @param $key
   * @return Object
   * @private
   */
  _getLocaleData($key){
    const data = {};

    Object.entries(config.locale.locales).forEach(([localeCode, locale]) => {
      data[localeCode] = locale[$key]
    });

    return data;
  }
}
